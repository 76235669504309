// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-news-template-js": () => import("./../src/news/template.js" /* webpackChunkName: "component---src-news-template-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experience-js": () => import("./../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-expertise-js": () => import("./../src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-no-exp-js": () => import("./../src/pages/no-exp.js" /* webpackChunkName: "component---src-pages-no-exp-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-pages-workshops-all-content-js": () => import("./../src/pages/workshops/all-content.js" /* webpackChunkName: "component---src-pages-workshops-all-content-js" */),
  "component---src-pages-workshops-elar-js": () => import("./../src/pages/workshops/elar.js" /* webpackChunkName: "component---src-pages-workshops-elar-js" */),
  "component---src-pages-workshops-english-learners-js": () => import("./../src/pages/workshops/english-learners.js" /* webpackChunkName: "component---src-pages-workshops-english-learners-js" */),
  "component---src-pages-workshops-high-stakes-testing-js": () => import("./../src/pages/workshops/high-stakes-testing.js" /* webpackChunkName: "component---src-pages-workshops-high-stakes-testing-js" */),
  "component---src-pages-workshops-math-js": () => import("./../src/pages/workshops/math.js" /* webpackChunkName: "component---src-pages-workshops-math-js" */),
  "component---src-pages-workshops-science-js": () => import("./../src/pages/workshops/science.js" /* webpackChunkName: "component---src-pages-workshops-science-js" */),
  "component---src-pages-workshops-shared-default-workshop-styles-js": () => import("./../src/pages/workshops/shared/default-workshop.styles.js" /* webpackChunkName: "component---src-pages-workshops-shared-default-workshop-styles-js" */),
  "component---src-pages-workshops-shared-workshop-js": () => import("./../src/pages/workshops/shared/workshop.js" /* webpackChunkName: "component---src-pages-workshops-shared-workshop-js" */),
  "component---src-pages-workshops-social-studies-js": () => import("./../src/pages/workshops/social-studies.js" /* webpackChunkName: "component---src-pages-workshops-social-studies-js" */),
  "component---src-pages-workshops-special-populations-js": () => import("./../src/pages/workshops/special-populations.js" /* webpackChunkName: "component---src-pages-workshops-special-populations-js" */),
  "component---src-pages-workshops-teacher-induction-js": () => import("./../src/pages/workshops/teacher-induction.js" /* webpackChunkName: "component---src-pages-workshops-teacher-induction-js" */)
}

